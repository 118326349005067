import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faCompass } from '@fortawesome/free-regular-svg-icons'
import { Col, Row, Stack } from 'react-bootstrap'
import Layout from '/src/components/layout'
import { formatAddress } from '/src/components/commerce/store/util'
import { formatEventDate } from '/src/components/events'

export const Head = () => {
  return (
    <>
      <title>Events | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}

export default ({ data }) => {
  const { body, field_event_address, field_event_date, relationships, title } = useMemo(() => {
    const [first] = data.allNodeEvents.edges
    return first.node
  }, [data])

  return (
    <Layout>
      <Row>
        <Col xs={12}>
          <div className="mt-5">
            <Link className="back-to-link" to='/community/events-field-days'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to events
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <div className="title-container mb-3">
            <h1 className="title">{title}</h1>
          </div>

          <Row className="mb-3">
            <Col xs={6}>
              <Stack direction="vertical">
                <label><FontAwesomeIcon icon={faCalendar} /> {formatEventDate(field_event_date)}</label>
                <label><FontAwesomeIcon icon={faCompass} /> {formatAddress(field_event_address)}</label>
              </Stack>
            </Col>
            <Col xs={6}>
              
            </Col>
          </Row>

          {body && (
            <div className="body mb-2" dangerouslySetInnerHTML={{
              __html: body.processed
            }}></div>
          )}
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    allNodeEvents(
      filter: {drupal_id: {eq: $id}}
      sort: {fields: field_event_date___value, order: DESC}
    ) {
      edges {
        node {
          field_event_address {
            address_line1
            address_line2
            administrative_area
            country_code
            family_name
            given_name
            langcode
            locality
            organization
            postal_code
          }
          field_event_date {
            value
            end_value
          }
          relationships {
            field_event_image {
              relationships {
                field_media_image_1 {
                  gatsbyImage(
                    fit: COVER
                    formats: AUTO
                    layout: FIXED
                    width: 10
                    quality: 100
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          body {
            processed
          }
          title
        }
      }
    }
  }
`
